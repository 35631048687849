import axios from "axios";

export const API_ORIGIN =
  process.env.VUE_APP_CORE_BACKEND_URL ||
  localStorage.getItem("VUE_APP_CORE_SERVER_ORIGIN");
export const BASE_API_URL = API_ORIGIN + "/api/v1";

export const authInterceptor = [
  (config) => {
    if (localStorage.getItem("VUE_APP_TOKEN")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "VUE_APP_TOKEN"
      )}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
];

export const authorizedReq = axios.create({
  params: { json: true },
});

authorizedReq.interceptors.request.use(...authInterceptor);
